import { RouterView } from "vue-router";

export const videosRoute = {
  path: ":menu/videos",
  component: <RouterView />,
  children: [
    {
      path: "list",
      component: () => import("@/views/videos/list/index.vue"),
    },
    {
      path: "list/class-hours/list/:videoId",
      component: () => import("@/views/video-class-hours/list/index.vue"),
    },
  ],
};
