import { computed } from "vue";
import { store } from "@/store";
import qs from "query-string";

const { schoolId: querySchoolId } = qs.parse(window.location.search);

export const useAuth = () => {
  const { dispatch, state } = store;

  const schoolId = computed(() => querySchoolId || state.auth.schoolId);

  const user = computed(
    () =>
      state.auth.user[querySchoolId || state.auth.schoolId] || { school: {} }
  );

  const getHeaders = () => {
    return { Authorization: `Bearer ${user.value.token}` };
  };

  const loggedIn = () => !!user.value.token;

  const login = (options) => dispatch("auth/login", options);

  const schoolLogin = (options) => dispatch("auth/schoolLogin", options);

  const logout = () => dispatch("auth/logout");

  const isSchoolManager = computed(() => user.value.type === "SchoolManager");

  const isTeacher = computed(() => user.value.type === "Teacher");

  return {
    schoolId,
    user,
    getHeaders,
    loggedIn,
    login,
    schoolLogin,
    logout,
    isSchoolManager,
    isTeacher,
  };
};
