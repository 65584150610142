export const useConsts = () => {
  // 是否是 Learn 平台
  const IsLearnPlatform = false;

  // 是否是 Admin 平台
  const IsAdminPlatform = true;

  // 基础地址
  const BaseUrl = process.env["BASE_URL"];

  // 接口地址
  const ApiUrl = process.env["VUE_APP_API_URL"];

  // 静态文件地址
  const CdnUrl = process.env["VUE_APP_CDN_URL"];

  // Office 预览服务地址
  const OfficeServiceUrl = process.env["VUE_APP_OFFICE_SERVICE_URL"];

  // Scratch 地址
  const ScratchUrl = process.env["VUE_APP_SCRATCH_URL"];

  // 分页大小
  const PageSize = 10;

  // 星期
  const Week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];

  return {
    IsLearnPlatform,
    IsAdminPlatform,
    BaseUrl,
    ApiUrl,
    CdnUrl,
    OfficeServiceUrl,
    ScratchUrl,
    PageSize,
    Week,
  };
};
