import { RouterView } from "vue-router";

export const experimentsRoute = {
  path: ":menu/experiments",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/experiments/list/index.vue"),
    },
    {
      path: ":experimentId/steps",
      component: () => import("@/views/experiments/list/steps/index.vue"),
    },
    {
      path: ":experimentId/experiment-projects",
      component: () => import("@/views/experiment-projects/list/index.vue"),
    },
  ],
};
