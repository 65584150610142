import helpers from "jt-helpers";
import { useConsts } from "@/composables/use-consts";

export const useHelpers = () => {
  const { ApiUrl } = useConsts();

  return {
    ...helpers,
    getImageUrl({ id }) {
      return `${ApiUrl}/public/files/${id}`;
    },
    getFileUrl({ id }) {
      return `${ApiUrl}/public/files/${id}`;
    },
    openWindow(url) {
      window.open(url);
    },
    clearHtmlTags(html) {
      return html.replace(/<[^>]+>/g, "");
    },
    download(url, fileName) {
      const xhr = new XMLHttpRequest();
      const urlSplit = url.split("/");

      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        const url = window.URL.createObjectURL(xhr.response);
        const a = document.createElement("a");

        a.href = url;
        a.download = fileName || urlSplit[urlSplit.length - 1];
        a.click();
      };

      xhr.send();
    },
  };
};
