import helpers from "jt-helpers";
import { publicAccountsApi } from "@/apis/public/accounts";
import qs from "query-string";

const { schoolId } = qs.parse(window.location.search);

const state = {
  schoolId: schoolId || 1,
  user: {},
};

const types = helpers.keyMirror({
  SetSchoolId: null,
  SetUser: null,
  ClearUser: null,
});

const mutations = {
  [types.SetSchoolId](state, { schoolId }) {
    state.schoolId = schoolId;
  },
  [types.SetUser](state, { school, ...rest }) {
    state.user[school.id] = { school, ...rest };
  },
  [types.ClearUser](state) {
    state.user[state.schoolId] = null;
  },
};

const actions = {
  async login({ commit }, { account, password }) {
    const { user, token } = await publicAccountsApi.post({
      action: "adminLogin",
      body: { account, password },
    });

    commit(types.SetSchoolId, { schoolId: user.school.id });
    commit(types.SetUser, { ...user, token });

    return { ...user, token };
  },
  async schoolLogin({ commit }, { id }) {
    const { user, token } =
      await require("@/apis/admin/schools").schoolsApi.post({
        action: "login",
        body: { id },
      });

    commit(types.SetUser, { ...user, token });

    return { ...user, token };
  },
  logout({ commit }) {
    commit(types.ClearUser);
    return null;
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
};
