import { adsRoute } from "./ads";
import { categoriesRoute } from "./categories";
import { classesRoute } from "./classes";
import { couponsRoute } from "./coupons";
import { coursesRoute } from "./courses";
import { examsRoute } from "./exams";
import { experimentsRoute } from "./experiments";
import { festivalsRoute } from "./festivals";
import { gamesRoute } from "./games";
import { homeRoute } from "./home";
import { modulesRoute } from "./modules";
import { ordersRoute } from "./orders";
import { productsRoute } from "./products";
import { schoolsRoute } from "./schools";
import { studentsRoute } from "./students";
import { teachersRoute } from "./teachers";
import { usersRoute } from "./users";
import { videosRoute } from "./videos";

export default [
  adsRoute,
  categoriesRoute,
  classesRoute,
  couponsRoute,
  coursesRoute,
  examsRoute,
  experimentsRoute,
  festivalsRoute,
  gamesRoute,
  homeRoute,
  modulesRoute,
  ordersRoute,
  productsRoute,
  schoolsRoute,
  studentsRoute,
  teachersRoute,
  usersRoute,
  videosRoute,
];
