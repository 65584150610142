import { RouterView } from "vue-router";

export const examsRoute = {
  path: ":menu/exams",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/exams/list/index.vue"),
    },
    {
      path: ":examId/questions",
      component: () => import("@/views/exam-questions/list/index.vue"),
    },
    {
      path: ":examId/results",
      component: () => import("@/views/exam-results/list/index.vue"),
    },
    {
      path: ":examId/results/:studentId",
      component: () => import("@/views/exam-results/detail/index.vue"),
    },
    {
      path: ":examId/result-reports",
      component: () => import("@/views/exam-results/reports/index.vue"),
    },
  ],
};
