import { RouterView } from "vue-router";

export const gamesRoute = {
  path: ":menu/games",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/games/list/index.vue"),
    },
    {
      path: ":gameId/forms",
      component: () => import("@/views/game-forms/list/index.vue"),
    },
  ],
};
