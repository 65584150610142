import { RouterView } from "vue-router";

export const coursesRoute = {
  path: ":menu/courses",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/courses/list/index.vue"),
    },
    {
      path: ":courseId/class-hours",
      component: () => import("@/views/course-hours/list/index.vue"),
    },
    {
      path: ":courseId/class-hours/:classHourId/course-hour-ppt-pages",
      component: () => import("@/views/course-hour-ppt-pages/index.vue"),
    },
  ],
};
