import { RouterView } from "vue-router";

export const classesRoute = {
  path: ":menu/classes-:type",
  component: <RouterView />,
  children: [
    {
      path: "",
      component: () => import("@/views/classes/list/index.vue"),
    },
    {
      path: ":classId?/students",
      component: () => import("@/views/students/list/index.vue"),
    },
    {
      path: ":classId/class-students",
      component: () => import("@/views/class-students/list/index.vue"),
    },
    {
      path: ":classId/schedules",
      component: () => import("@/views/class-schedules/list/index.vue"),
    },
  ],
};
